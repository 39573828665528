import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#f9bf29';

const AuslotsPage = ({ data }) => (
  <>
    <SEO
      title="Les meilleurs bons de Neosurf – 500 $ et 125 tours gratuits – AUSlots"
      description="Trouve le meilleur Code Bonus de neosurf en ligne. Obtiens jusqu’à 500 $ bonus et 125 Tours Gratuits dans des Machines à sous Géniaux et Uniques – AUSlots.com"
      alternateLanguagePages={[
        {
          path: 'casino/auslots',
          language: 'en'
        },
        {
          path: 'casino/auslots',
          language: 'fr'
        },
        {
          path: 'casino/auslots',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="Auslots offre des machines à sous impressionnantes et uniques" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">Code bonus de Neosurf exclusif &amp; le mieux noté sur AUSlots.com</h1>
            <p className="textColorLight">Des machines à sous impressionnantes et uniques sur AUSlots.com!</p>
            <p className="textColorDark">L’AUSlots t’apporte une sélection de premier ordre de plus de 1000 machines à sous en ligne &amp; des jeux de casino. Des dernières machines à sous avec de superbes graphiques 3D aux créneaux classiques de tous les temps. Préfères-tu les jeux de table? L’AUSlots propose les deux jeux, de casino animes et en direct, diffusés en temps réel 24/7 via des caméras HD. Est-ce que tu aimes des jeux de vidéo de poker? Ou peut-être des sports virtuels? <strong>Il suffit les nommer, L’AUSlots Casino les a tous!</strong> De plus, les joueurs d’AUSlots sont régulièrement traités avec de généreuses offres de bonus, y compris des tours gratuits sans parier, des Remboursements &amp; des Bons Exclusifs! Si tu recherches le code de bonus de Neosurf exclusif et spécifique, tu es au bon endroit. Découvre plus d’informations ci-dessous.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">INFORMATIONS SUR LE CODE DE BONUS DE NEOSURF</h2>
            <ul>
              <li>125% jusqu’à 500 $ / € et 125 tours gratuits sans mise</li>
              <li>Les tours gratuits sont sur Dragon Kings, Spinfinity Man ou Spinions Beach Party (le créneau que tu ouvres en premier parmi ceux proposés)</li>
              <li>Utilise le code : NEO125+125</li>
              <li>Le dépôt minimum est de 20 $/€</li>
              <li>La cagnotte est fois trente (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">OUVRIR CASINO AUSLOTS</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Code bonus de Neosurf NEO125+125" />
        </div>
      </div>
    </div>
  </>
)

export default AuslotsPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/auslots/AUSlots-delivers-Awesome-&-Unique-Slots.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/auslots/NEO125+125-Neosurf-Bonus-Code.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
